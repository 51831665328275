import i18n from 'utils/i18n';
import React, { useState, useRef, useEffect, useCallback, Children } from 'react';
import classNames from 'classnames';
import { loadCaptcha } from 'utils/captcha';
import { isPrivate } from 'utils/env';
import { VoiceType, TimbreItem, SpeedItem } from 'pages/image_configure/types';
import {
  getTimbreList,
  DEFAULT_TIMBRE_CATEGORY,
  getProtoTimbreList,
  GetTimbreListReq,
  getNewBroadcastTTS,
  GetBroadcastTTSReq,
  getBroadcastVideoProgress,
  TimbreSetting,
} from 'apis';
import { SystemDictType } from 'types/index';
import { AuditionParams, DEFAULT_AFFECTIVELEVEL } from './components/AuditionSetting';

import { Icon, Empty, Loading } from 'components/common';
import { SettingCard, SelectItem, Process, AuditionSetting } from './components';
import { Tabs, Spin, message, Select, Input, Form, Cascader } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Popup, DialogPlugin } from 'tdesign-react';
import InfiniteScroll from 'react-infinite-scroll-component';

import './index.scss';
const { t } = i18n;
const { TabPane } = Tabs;

let timer: ReturnType<typeof setInterval> | null = null;

type IProps = {
  isTabShow: boolean;
  type?: string; // 'photo'为仅试听
  value: VoiceType;
  speedList?: SpeedItem[];
  modelSource: string;
  timbreCategoryEnum: SystemDictType[];
  timbreLangugeList?: SystemDictType[];
  timbreAuditionTextList: SystemDictType[];
  disabled?: boolean;
  virtualmanTypeCode: string;
  limit?: number;
  virtualmanResourceId?: number;
  virtualmanCode?: string;
  onValueChange?: Function;
  isInitializing?: boolean;
  fastVoiceSpeedWarn?: boolean;
  isBroadcast?: boolean;
  onChangeTimer?: () => Promise<void>;
};

type TimbreFilter = {
  timbreGender: string;
  timbreName: string;
  timbreCategory: string;
  timbreLanguageLabel: string;
  timbreSelectedLanguage: string;
};

const timbreInitialFilter = {
  timbreCategory: '',
  timbreName: '',
  timbreGender: '',
  timbreLanguageLabel: '',
  timbreSelectedLanguage: '',
};

const TimbreList = (props: IProps) => {
  const {
    isTabShow,
    value: {
      timbreCode,
      speedCode,
      volume = 0,
      emotionCategory = '',
      emotionIntensity = 0,
      platformSource = 0,
      timbreLanguage = '',
    },
    speedList = [],
    modelSource,
    timbreCategoryEnum,
    timbreLangugeList,
    timbreAuditionTextList,
    disabled = false,
    virtualmanResourceId = 0,
    virtualmanTypeCode = '',
    limit = 20,
    type = '',
    virtualmanCode,
    isInitializing = false,
    fastVoiceSpeedWarn,
    isBroadcast = false,
    onChangeTimer,
  } = props;
  const [form] = Form.useForm();
  const timbreCategoryList = timbreCategoryEnum.map((item) => {
    let children;
    if (item.dictCode === 'foreignlanguage') {
      children = timbreLangugeList?.map((item) => ({
        value: item.dictCode,
        label: item.dictName,
      }));
    }
    return {
      value: item.dictValue,
      label: item.dictName,
      children,
    };
  });
  const [playCode, setPlayCode] = useState<string>('');
  const [audioSrc, setAudioSrc] = useState<string>('');
  const [prevPlayCode, setPrevPlayCode] = useState<string>('');
  const [progress, setProgress] = useState(0);
  const [timbreList, setTimbreList] = useState<TimbreItem[]>([]);
  const [timbreFilter, setTimbreFilter] = useState<TimbreFilter>();
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [auditionLoading, setAuditionLoading] = useState(false);
  const [auditionModalVisible, setAuditionModalVisible] = useState(false);
  const [isAudition, setIsAudition] = useState(false);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  let isCaptchaShow = false; // 防止点击验证码蒙层是试听弹窗关闭&避免render导致试听参数值重置

  const [currentTimbreName, setCurrentTimbreName] = useState<TimbreItem>();

  useEffect(() => {
    if (!virtualmanResourceId && modelSource.includes('pcg_ai')) return;
    if (!virtualmanCode && !modelSource.includes('pcg_ai')) return;
    if (!timbreFilter) return;
    setLoading(true);
    setTotalCount(0);
    setTimbreList([]);
    const payload = modelSource.includes('pcg_ai')
      ? {
          offset: 0,
          limit,
          virtualmanTypeCode,
          virtualmanResourceId,
          ...getTimbreFilter(),
        }
      : {
          offset: 0,
          limit,
          virtualmanTypeCode,
          virtualmanCode, // 数智人code，筛选形象性别用
          ...getTimbreFilter(),
        };
    getTimbreListByType(payload)
      .then(({ timbres, totalCount }) => {
        setOffset(timbres.length);
        setTimbreList(timbres);
        setTotalCount(totalCount);
        if (!!timbres[0]?.timbreCode && !timbreCode && !isInitializing) {
          // console.log(11, timbres[0]?.timbreCode);
          const { timbreCode: newTimbreCode, timbreName = '', platformSource = 0, language } = timbres[0];
          changeValue({ timbreCode: newTimbreCode, timbreName, platformSource, language });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [timbreFilter]);

  useEffect(() => {
    // 初始化pcg_ai形象,
    // 我的数智人modelSource更新晚于virtualmanResourceId，监听modelSource变化兼容
    if (!virtualmanResourceId || !timbreFilter) return;
    form.resetFields();
    setTimbreFilter(timbreInitialFilter);
    setLoading(true);
    setTotalCount(0);
    setTimbreList([]);
    getTimbreListByType({
      offset: 0,
      limit,
      virtualmanTypeCode,
      virtualmanResourceId,
      ...getTimbreFilter(true),
    })
      .then(({ timbres, totalCount }) => {
        setOffset(timbres.length);
        setTimbreList(timbres);
        setTotalCount(totalCount);
        if (!!timbres[0]?.timbreCode && !isInitializing) {
          // console.log('init timbreCode 1', timbres[0]?.timbreCode);
          // if (timbres.find((i) => i.timbreCode === timbreCode)) return;
          const {
            timbreCode: newTimbreCode,
            timbreName = '',
            platformSource = 0,
            emotionalStyles,
            language,
            supportLanguages,
          } = timbres[0];
          changeValue({
            timbreCode: newTimbreCode,
            timbreName,
            platformSource,
            emotionCategory: emotionalStyles.length ? emotionalStyles[0].code : '',
            language,
            timbreLanguage: supportLanguages.length ? supportLanguages[0].code : '',
          });
        }
        // 当前分类下形象无音色清空
        if (!timbres[0]?.timbreCode && !isInitializing)
          changeValue({
            timbreCode: '',
            timbreName: '',
            emotionCategory: '',
            platformSource: 0,
            language: '',
            timbreLanguage: '',
          });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [virtualmanResourceId, modelSource]);

  useEffect(() => {
    if (!timbreCode || !timbreList.length) return;

    const currentTimbre = timbreList?.find((i) => i.timbreCode === timbreCode);
    setCurrentTimbreName(currentTimbre ? currentTimbre : currentTimbreName);
    if (currentTimbre) {
      changeValue({
        emotionalStyles: currentTimbre.emotionalStyles,
        supportLanguages: currentTimbre.supportLanguages,
      });
    }
  }, [timbreCode, timbreList]);

  // useEffect(() => {
  //   // 初始化自研形象
  //   // console.log(33, virtualmanCode, timbreCategory, modelSource.includes('pcg_ai'));
  //   if (!virtualmanCode || !timbreCategory || modelSource.includes('pcg_ai')) return;
  //   setLoading(true);
  //   setTotalCount(0);
  //   setTimbreList([]);
  //   getTimbreListByType({
  //     offset: 0,
  //     limit,
  //     timbreCategory,
  //     virtualmanTypeCode,
  //     virtualmanCode, // 数智人code，筛选形象性别用
  //   })
  //     .then(({ timbres, totalCount }) => {
  //       setOffset(timbres.length);
  //       setTimbreList(timbres);
  //       setTotalCount(totalCount);
  //       if (timbres[0]?.timbreCode && !isInitializing) {
  //         // console.log('init timbreCode 2', timbreCode);
  //         // if (timbres.find((i) => i.timbreCode === timbreCode)) return;
  //         const { timbreCode: newTimbreCode, timbreName = '', platformSource = 0 } = timbres[0];
  //         changeValue({ timbreCode: newTimbreCode, timbreName, platformSource });
  //       }
  //       // 当前分类下形象无音色清空
  //       if (!timbres[0]?.timbreCode && !isInitializing)
  //         changeValue({ timbreCode: '', timbreName: '', platformSource: 0 });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [virtualmanCode]);

  useEffect(() => {
    if (!timbreCategoryEnum.length) return;
    setTimbreFilter(timbreInitialFilter);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timbreCategoryEnum]);

  // 切换形象speedList变化后speedCode不存在兼容
  useEffect(() => {
    if (!!speedCode && !!speedList.length && speedList.findIndex((i) => i.speedCode === speedCode) === -1) {
      changeValue({ speedCode: speedList[0].speedCode });
    }
  }, [speedList]);

  useEffect(() => {
    if (audioSrc && prevPlayCode === playCode) {
      setPlay();
    }
  }, [audioSrc]);

  // tab切换后停止试听
  useEffect(() => {
    if (!isTabShow || (!auditionModalVisible && !audioSrc)) stop();
  }, [isTabShow, auditionModalVisible]);

  const getTimbreListByType = (payload: GetTimbreListReq) => {
    if (type === 'photo') {
      return getProtoTimbreList(payload);
    }
    return getTimbreList(payload);
  };

  const getTimbreFilter = useCallback(
    (initial = false) => {
      const { timbreCategory, timbreGender, timbreLanguageLabel, timbreSelectedLanguage } = initial
        ? timbreInitialFilter
        : timbreFilter || {};
      const timbreName = form.getFieldValue('timbreName') || '';
      return {
        ...(timbreCategory ? { timbreCategory } : null),
        timbreFilter: {
          ...(timbreName ? { timbreName } : null),
          ...(timbreGender && timbreGender !== '0' ? { timbreGender: +timbreGender } : null),
          ...(timbreLanguageLabel && timbreLanguageLabel !== '0' ? { timbreLanguageLabel } : null),
          ...(timbreSelectedLanguage ? { timbreSelectedLanguage } : null),
          ...(timbreCode
            ? {
                timbreSelectedCode: timbreCode,
              }
            : {}),
        },
      };
    },
    [timbreCode, timbreFilter],
  );

  const onNext = () => {
    setLoading(true);
    const payload = modelSource.includes('pcg_ai')
      ? {
          offset,
          limit,
          virtualmanTypeCode,
          virtualmanResourceId,
          ...getTimbreFilter(),
        }
      : {
          offset,
          limit,
          virtualmanTypeCode,
          virtualmanCode, // 数智人code，筛选形象性别用
          ...getTimbreFilter(),
        };
    getTimbreListByType(payload)
      .then(({ timbres, totalCount }) => {
        setOffset(offset + timbres.length);
        setTimbreList([...timbreList, ...timbres]);
        setTotalCount(totalCount);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onVoiceSpeedChange = (item: SpeedItem) => {
    if (Number(item.speedValue) > 1 && fastVoiceSpeedWarn) {
      const warnDialog = DialogPlugin.confirm({
        header: t('提示信息'),
        confirmBtn: t('好的'),
        cancelBtn: null,
        theme: 'warning',
        body: t('调快语速可能导致数智人没有充足时间做出连续动作，请关注您在场景应用中配置的动作是否受影响。'),
        onConfirm() {
          warnDialog.hide();
        },
        onClose() {
          warnDialog.hide();
        },
      });
    }
    changeValue({ speedCode: item.speedCode });
  };

  const changeValue = (item: VoiceType) => {
    props.onValueChange?.(item);
    if (item?.timbreCode !== playCode) {
      stop();
    }
  };

  const play = (key: string, url: string) => {
    stop();
    setPlayCode(key);
    // pcg_ai或私有化场景下直接播放
    if (!modelSource.includes('pcg_ai')) return;
    if (!url) {
      message.warning(t('暂无试听音频文件'));
      stop();
      return;
    }
    setPrevPlayCode(key);
    setAudioSrc(url);

    // 自研tts试听
    // getTtsAudition({ sentence: '', speedCode: speedCode || '', timbreCode: key || '' })
    //   .then(async (res) => {
    //     if (res.audio) {
    //       const audioBase64Str = `data:audio/wav;base64,${res.audio}`;
    //       const audioBlob = await utils.dataURItoBlob(audioBase64Str);
    //       const blobUrl = window.URL.createObjectURL(audioBlob);
    //       setPrevPlayCode(key);
    //       setAudioSrc(blobUrl);
    //     }
    //   })
    //   .catch(() => {
    //     stop();
    //   });
  };

  const stop = () => {
    setPlayCode('');
    setPrevPlayCode('');
    setAudioSrc('');
    setAuditionLoading(false);
    setIsAudition(false);
    if (timer) clearInterval(timer);
  };

  const setPlay = () => {
    audioRef?.current?.play().catch(() => {
      stop();
    });
  };

  const onAudioTimeUpdate = (e: any) => {
    const { currentTime, duration } = e.target;
    setProgress(currentTime / duration || 0);
  };

  const reqAudition = (params: AuditionParams) => {
    play(params.timbreKey, '');
    setIsAudition(true);
    const speedValue = speedList.find((i) => i.speedCode === speedCode)?.speedValue || 1;
    const callback = (res: {
      ret: number;
      ticket: string;
      randstr: string;
      errorCode?: number;
      errorMessage?: string;
    }) => {
      const { ret, errorCode } = res;
      // 成功或主动关闭验证码，修改状态，可关闭试听弹窗
      if ([0, 2].includes(ret)) isCaptchaShow = false;
      if (ret !== 0) return;
      if (errorCode === 1001) {
        message.warning(t('加载验证码服务失败，请刷新重试'));
        return;
      }
      getTTSAuditionId({
        ...params,
        speed: +speedValue || 1,
        ticket: res.ticket,
        randomData: res.randstr,
        isAudition: true,
      });
    };
    // if (process.env.REACT_APP_ENVIRONMENT !== 'private') {
    // 防止点击蒙层试听弹窗关闭
    isCaptchaShow = true;
    loadCaptcha(callback);
    // } else {
    //   getTTSAuditionId({
    //     ...params,
    //     speed: +speedValue || 1,
    //     isAudition: true,
    //   });
    // }
  };

  const getTTSAuditionId = (payload: GetBroadcastTTSReq) => {
    setAuditionLoading(true);
    getNewBroadcastTTS({ ...payload })
      .then((taskIds) => {
        const { timbreKey } = payload;
        if (!taskIds?.length) return;
        timer = setInterval(() => {
          // console.log('timer1', timer);
          getTTSAuditionResult(taskIds[0], timbreKey);
        }, 1500);
      })
      .catch(() => setAuditionLoading(false));
  };

  const getTTSAuditionResult = (name: string, key: string) => {
    getBroadcastVideoProgress({ name })
      .then(({ videoUrl, status }) => {
        if (status === 1) {
          if (timer) clearInterval(timer);
          setPrevPlayCode(key);
          setAudioSrc(videoUrl);
          setAuditionLoading(false);
        }
      })
      .catch(() => {
        stop();
        setAuditionLoading(false);
      });
  };

  const saveTimbreSetting = async (item: TimbreItem, setting: TimbreSetting) => {
    // console.log(item, setting);
    setCurrentTimbreName(item);
    changeValue({ timbreCode: item.timbreCode, timbreName: item.timbreName, ...setting });
    setAuditionModalVisible(false);
    // 切换为微软音色
    if (setting.platformSource !== platformSource && setting.platformSource === 5) {
      try {
        await onChangeTimer?.();
      } catch (error) {
        return;
      }
    }
    changeValue({
      timbreCode: item.timbreCode,
      timbreName: item.timbreName,
      language: item.language,
      emotionalStyles: item.emotionalStyles,
      supportLanguages: item.supportLanguages,
      ...setting,
    });
  };

  const handleChangeDesc = () => {
    timbreFilter && setTimbreFilter({ ...timbreFilter, timbreName: form.getFieldValue('timbreName') || '' });
  };
  const getTagName = (i: TimbreItem) => {
    const { timbreCategory, labels } = i;
    if (i.isRecommend === 1) return <span className="recommend-tag">{t('推荐')}</span>;
    if (timbreCategory === '1' && labels?.length) {
      let tagName = '';
      if (labels?.includes('ZERO_SHOT_VOICE')) {
        tagName = t('极速版');
      } else if (labels?.includes('VOICE')) {
        tagName = t('基础版');
      } else if (labels?.includes('MULTILINGUAL')) {
        tagName = t('多语种');
      }
      return <span className="recommend-tag">{tagName}</span>;
    }
    return '';
  };
  return (
    <>
      <div className="tab-timbre">
        {!!speedList.length && (
          <SettingCard title={t('语速')}>
            {speedList.map((i) => (
              <SelectItem
                key={i.speedCode}
                selected={speedCode === i.speedCode}
                disabled={disabled}
                className={classNames('speed-item', { disabled })}
                onChange={onVoiceSpeedChange.bind(null, i)}
              >
                <div className="text">{i.speedName}</div>
              </SelectItem>
            ))}
          </SettingCard>
        )}
        <div className="timbre-category-card">
          <div
            className="setting-card-title timbre-category-header"
            style={!isBroadcast ? { width: 360, justifyContent: 'space-between' } : {}}
          >
            <span>{t('音色')}</span>
            <span style={isBroadcast ? { marginLeft: 16 } : {}} className="timbre-category-name">
              {t('当前音色：{{timbreDesc}} {{timbreName}}', {
                timbreDesc: currentTimbreName?.timbreDesc,
                timbreName: currentTimbreName?.timbreName,
              })}
            </span>
          </div>
          <Form<TimbreFilter> form={form}>
            {!isBroadcast && (
              <Form.Item name="timbreName">
                <Input
                  onPressEnter={handleChangeDesc}
                  disabled={disabled}
                  style={{ width: isBroadcast ? 170 : 360, marginBottom: 16 }}
                  placeholder={t('请输入关键词搜索')}
                  suffix={<SearchOutlined onClick={handleChangeDesc} style={{ cursor: 'pointer' }} />}
                />
              </Form.Item>
            )}
            <div className="timbre-category-filter" style={{ columnGap: 16 }}>
              {isBroadcast && (
                <Form.Item name="timbreName">
                  <Input
                    onPressEnter={handleChangeDesc}
                    disabled={disabled}
                    style={{ width: isBroadcast ? 170 : 360 }}
                    placeholder={t('请输入关键词搜索')}
                    suffix={<SearchOutlined onClick={handleChangeDesc} style={{ cursor: 'pointer' }} />}
                  />
                </Form.Item>
              )}
              <Form.Item name="timbreGender">
                <Select
                  style={{ width: isBroadcast ? 170 : 110 }}
                  placeholder={t('选择性别')}
                  disabled={disabled}
                  onChange={(value) => timbreFilter && setTimbreFilter({ ...timbreFilter, timbreGender: value })}
                  allowClear
                  options={[
                    {
                      value: '0',
                      label: t('全部'),
                    },
                    {
                      value: '1',
                      label: t('男性'),
                    },
                    {
                      value: '2',
                      label: t('女性'),
                    },
                    {
                      value: '3',
                      label: t('中性'),
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="timbreCategory">
                {/* <Select
                  style={{ width: isBroadcast ? 170 : 110 }}
                  placeholder={t('选择类型')}
                  onChange={(value) => timbreFilter && setTimbreFilter({ ...timbreFilter, timbreCategory: value })}
                  disabled={disabled}
                  allowClear
                  options={timbreCategoryList}
                  virtual={false}
                /> */}
                <Cascader
                  allowClear
                  changeOnSelect
                  style={{ width: isBroadcast ? 170 : 110 }}
                  placeholder={t('选择类型')}
                  onChange={(value) => {
                    timbreFilter &&
                      setTimbreFilter({
                        ...timbreFilter,
                        timbreCategory: value && value.length > 0 ? (value[0] as string) : '',
                        timbreSelectedLanguage: value && value.length > 1 ? (value[1] as string) : '',
                      });
                  }}
                  disabled={disabled}
                  options={timbreCategoryList}
                />
              </Form.Item>
              <Form.Item name="timbreLanguageLabel">
                <Select
                  style={{ width: isBroadcast ? 170 : 110 }}
                  placeholder={t('选择语言功能')}
                  onChange={(value) => timbreFilter && setTimbreFilter({ ...timbreFilter, timbreLanguageLabel: value })}
                  disabled={disabled}
                  allowClear
                  options={[
                    {
                      value: '0',
                      label: t('全部'),
                    },
                    {
                      value: 'monolingual',
                      label: t('单语种'),
                    },
                    {
                      value: 'multilingual',
                      label: t('多语种'),
                    },
                  ]}
                  virtual={false}
                />
              </Form.Item>
            </div>
          </Form>
        </div>

        <div id="scrollableTimbreList" className="timbre-list-wrapper">
          <Spin spinning={loading}>
            <InfiniteScroll
              dataLength={timbreList.length}
              next={onNext}
              hasMore={timbreList.length < totalCount}
              loader={<Loading />}
              scrollableTarget="scrollableTimbreList"
            >
              <SettingCard title="" style={{ marginTop: 0 }} className="timbre-setting-card setting-card-large">
                {timbreList.map((i) => {
                  const isPlaying = playCode === i.timbreCode;
                  return (
                    <SelectItem
                      key={i.timbreCode}
                      disabled={disabled}
                      selected={timbreCode === i.timbreCode}
                      className={classNames('timbre-item', { isRecommend: i.isRecommend === 1 })}
                      onChange={(e: any) => {
                        if (
                          (!disabled || timbreCode === i.timbreCode) &&
                          type !== 'photo' &&
                          !modelSource.includes('pcg_ai') &&
                          !isPrivate
                        ) {
                          return;
                        }
                        e.target.className !== 'rticon icon-play' &&
                          changeValue({
                            timbreCode: i.timbreCode,
                            timbreName: i.timbreName,
                            platformSource: i.platformSource,
                            language: i.language,
                          });
                      }}
                    >
                      <Popup
                        visible={auditionModalVisible && playCode === i.timbreCode && !modelSource.includes('pcg_ai')}
                        onVisibleChange={(val) => {
                          if (!isCaptchaShow) setAuditionModalVisible(val);
                        }}
                        destroyOnClose
                        showArrow
                        placement="bottom-left"
                        overlayClassName="audition-popup-content"
                        content={
                          <AuditionSetting
                            item={i}
                            disabled={disabled}
                            loading={auditionLoading}
                            isAudition={isAudition}
                            mode={type === 'photo' ? 'try' : 'save'}
                            timbreSetting={{ timbreCode, volume, emotionCategory, emotionIntensity, timbreLanguage }}
                            onReqAudition={reqAudition}
                            onCloseAuditionPopup={() => setAuditionModalVisible(false)}
                            onSaveTimbreSetting={saveTimbreSetting}
                            timbreAuditionTextList={timbreAuditionTextList}
                          />
                        }
                        trigger="click"
                      >
                        <div
                          className="setting-item-content"
                          // style={{ paddingRight: 20, width: 166 }}
                          onClick={(e: any) => {
                            if (!disabled || timbreCode === i.timbreCode) {
                              if (type !== 'photo' && !modelSource.includes('pcg_ai')) e.stopPropagation();
                              isPlaying || modelSource.includes('pcg_ai') ? stop() : play(i.timbreCode, i.timbreUrl);
                            }
                          }}
                        >
                          <div className={classNames('setting-item-audition', isPlaying ? 'on' : 'off')}>
                            <div>
                              <Process
                                className="setting-item-audition-icon"
                                center={
                                  <Icon
                                    name={isPlaying && !!audioSrc ? 'stop' : 'play'}
                                    size={16}
                                    color={'rgba(15, 24, 41, 0.8)'}
                                  />
                                }
                                process={isPlaying ? progress : 0}
                                fill={'#F2F4F7'} // 中心
                                stroke={'#0F1829'} // 线
                                size={32}
                                onClick={(e: any) => {
                                  if (!disabled || timbreCode === i.timbreCode) {
                                    if (modelSource.includes('pcg_ai')) e.stopPropagation();
                                    isPlaying ? stop() : play(i.timbreCode, i.timbreUrl);
                                  } else {
                                    e.stopPropagation();
                                  }
                                }}
                              />
                            </div>

                            <div>
                              <div className="text" style={{ width: 78 }}>
                                {i.timbreDesc}
                              </div>
                              <div className="sub-text" style={{ width: 78 }} title={i.timbreName}>
                                {i.timbreName}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Popup>
                      {getTagName(i)}
                    </SelectItem>
                  );
                })}
              </SettingCard>
            </InfiniteScroll>
          </Spin>
          {!timbreList.length && !loading && <Empty prompt={t('暂无可用音色资源')} />}
        </div>

        <audio
          ref={(node) => (audioRef.current = node)}
          src={audioSrc}
          onTimeUpdate={(e) => onAudioTimeUpdate(e)}
          // onEnded={stop}
          onEnded={() => setAudioSrc('')}
        />
      </div>
    </>
  );
};

export default React.memo(TimbreList);
