import i18n from 'utils/i18n';
import React, { useEffect, useMemo, useState } from 'react';

import { Textarea, Select, Button, Slider } from 'tdesign-react';
import { message } from 'antd';
import commonUtils from 'utils';
import classNames from 'classnames';

import { TimbreItem } from 'pages/image_configure/types';
import { EMOTIONAL_TIMBRE_CATEGORY, ENG_TIMBRE_CATEGORY, TimbreSetting } from 'apis';

import './index.scss';
import { CloseIcon } from 'tdesign-icons-react';
import { SystemDictType } from 'types';
const { t } = i18n;
type AuditionSettingProps = {
  item: TimbreItem;
  onReqAudition: Function;
  loading: boolean;
  onCloseAuditionPopup: Function;
  mode?: 'try' | 'save'; // 仅试听 || 可保存
  timbreSetting?: TimbreSetting;
  onSaveTimbreSetting: Function;
  disabled?: boolean;
  isAudition: boolean;
  timbreAuditionTextList: SystemDictType[];
};

export type AuditionParams = {
  timbreKey: string;
  volume: number;
  inputSsmlArray: string[];
  emotionCategory?: string;
  timbreLanguage?: string;
  emotionIntensity?: number;
  platformSource?: number;
  timbreCategory?: string;
};
// 有默认增益的音色
export const DEFAULT_VOLUME_SPECIAL: { [key: string]: number } = {
  '171060': 10,
  '10001': 10,
  '20001': 10,
  '30001': 10,
  '10031': 10,
  '10032': 10,
};
export const DEFAULT_AFFECTIVELEVEL = 125;
const DEFAULT_VOLUME = 0; // tdesign-slider最小值label显示number 0有问题，需转成字符串
export const MIN_EMOTIONINTENSITY = 50;

const AuditionSetting = (props: AuditionSettingProps) => {
  const {
    item: {
      platformSource = 0,
      emotionalStyles = [],
      timbreCode,
      timbreCategory,
      language,
      timbreType,
      supportLanguages = [],
      speaker = '',
    },
    onReqAudition,
    loading,
    onCloseAuditionPopup,
    mode,
    timbreSetting = {},
    onSaveTimbreSetting,
    disabled = false,
    isAudition = false,
    timbreAuditionTextList,
  } = props;

  const {
    volume: volumeVal,
    emotionIntensity: emotionIntensityVal,
    emotionCategory: emotionCategorVal,
    timbreLanguage: timbreLanguageVal,
    timbreCode: timbreCodeVal,
  } = timbreSetting;

  const timbreAuditionList = useMemo(() => {
    return timbreAuditionTextList.map((item) => ({
      value: item.dictCode,
      content: item.dictValue,
    }));
  }, [timbreAuditionTextList]);

  const currentLanguage =
    timbreAuditionList.find((item) => item.value === language)?.content ||
    'Hello, I am a digital human driven by artificial intelligence and can communicate with you.';
  const disableLanguageFlag = platformSource === 4 && speaker.startsWith('W');

  const DEFAULT_TEXT =
    timbreCategory === ENG_TIMBRE_CATEGORY
      ? currentLanguage
      : t('大家好，我是由人工智能驱动的数智人，能像真人一样与人沟通，希望大家喜欢我。');

  const [textValue, setTextValue] = useState(DEFAULT_TEXT);
  const [emotionCategory, setEmotionCategory] = useState('');
  const [timbreLanguage, setTimbreLanguage] = useState('');
  const [emotionIntensity, setEmotionIntensity] = useState(DEFAULT_AFFECTIVELEVEL);
  const [volume, setVolume] = useState(DEFAULT_VOLUME);
  const [maxCount, setMaxCount] = useState(100);

  useEffect(() => {
    // 切换音色音色参数重置
    if (timbreCode !== timbreCodeVal || !emotionCategorVal) {
      setEmotionCategory(emotionalStyles?.[0]?.code || '');
      setTimbreLanguage(supportLanguages?.[0]?.code || '');
    }
  }, [emotionalStyles, supportLanguages]);

  useEffect(() => {
    const content = timbreAuditionList.find((item) => item.value === timbreLanguage)?.content || textValue;
    setTextValue(content);
    setMaxCount(content.length > 100 ? content.length : 100);
  }, [timbreLanguage, timbreAuditionList]);

  useEffect(() => {
    // 防止播放中父组件render导致参数值重置
    if (!timbreSetting || isAudition || timbreCode !== timbreCodeVal) return;
    if (volumeVal !== undefined) setVolume(volumeVal);
    if (!!emotionIntensityVal) setEmotionIntensity(emotionIntensityVal);
    if (!!emotionCategorVal) setEmotionCategory(emotionCategorVal);
    if (!!timbreLanguageVal) setTimbreLanguage(timbreLanguageVal);
  }, [timbreSetting]);

  const resetSetting = () => {
    setEmotionCategory(!!emotionalStyles?.length ? emotionalStyles[0].code : '');
    setTimbreLanguage(!!supportLanguages?.length ? supportLanguages[0].code : '');
    setEmotionIntensity(DEFAULT_AFFECTIVELEVEL);
    setVolume(DEFAULT_VOLUME);
    setTextValue(DEFAULT_TEXT);
  };

  const reqAudition = () => {
    const utf8Length = commonUtils.getLengthOfUtf8(textValue);
    // tts播报文本utf8编码字符最小长度
    if (utf8Length < 4) {
      message.error(t('字数过短'));
      return;
    }
    let payload: AuditionParams = {
      timbreKey: timbreCode,
      volume,
      inputSsmlArray: [textValue],
      platformSource,
      timbreCategory,
      ...(timbreLanguage ? { timbreLanguage } : null),
    };
    if (timbreCategory === EMOTIONAL_TIMBRE_CATEGORY) payload = { emotionCategory, emotionIntensity, ...payload };
    onReqAudition(payload);
  };

  const closeAuditionPopup = () => {
    onCloseAuditionPopup();
  };

  const saveSetting = () => {
    if (!!disabled) {
      message.info(t('请先点击编辑按钮进入编辑状态，再保存音色设置'));
      return;
    }
    let setting: TimbreSetting = {
      volume,
      platformSource,
      emotionCategory: '',
      emotionIntensity: 0,
      timbreLanguage,
    };
    if (timbreCategory === EMOTIONAL_TIMBRE_CATEGORY) setting = { ...setting, emotionCategory, emotionIntensity };
    onSaveTimbreSetting(props.item, setting);
  };

  return (
    <div className="audition-setting" onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
      <div className="audition-title">
        {t('试听')}
        <CloseIcon className="icon-close-audition" onClick={closeAuditionPopup} />
      </div>
      <Textarea
        autosize={{ minRows: 5 }}
        value={textValue}
        placeholder={t('请输入试听文本')}
        maxlength={maxCount}
        onChange={(val) => setTextValue(val)}
      />
      {timbreCategory === EMOTIONAL_TIMBRE_CATEGORY && (
        <>
          <div className="audition-item">
            <div className="item-label">{t('情感风格')}</div>
            <Select
              value={emotionCategory}
              onChange={(val) => setEmotionCategory(val as string)}
              style={{ width: '120px' }}
              size="small"
              options={emotionalStyles.map((i) => {
                return { label: i.name, value: i.code };
              })}
            />
          </div>
          <div className="audition-item">
            <div className="item-label">{t('情感程度')}</div>
            <Slider
              value={emotionIntensity}
              max={200}
              min={MIN_EMOTIONINTENSITY}
              step={3}
              label={`${(Math.max(emotionIntensity, MIN_EMOTIONINTENSITY) - MIN_EMOTIONINTENSITY) / 1.5}`}
              style={{ width: '120px' }}
              onChange={(val) => setEmotionIntensity(val as number)}
            />
          </div>
        </>
      )}
      {supportLanguages.length > 0 && (
        <div className="audition-item">
          <div className="item-label">{t('语种选择')}</div>
          <Select
            value={timbreLanguage}
            onChange={(val) => {
              setTimbreLanguage(val as string);
            }}
            style={{ width: '120px' }}
            size="small"
            options={supportLanguages.map((i) => {
              return { label: i.name, value: i.code };
            })}
          />
        </div>
      )}
      {!(timbreType in DEFAULT_VOLUME_SPECIAL) && (
        <div className="audition-item">
          <div className="item-label">{t('音量增益')}</div>
          <Slider
            value={volume}
            max={10}
            min={0}
            step={1}
            label={volume}
            style={{ width: '120px' }}
            onChange={(val) => setVolume(val as number)}
          />
        </div>
      )}
      <div className="audition-footer">
        <Button variant="outline" className="operate-btn" onClick={resetSetting}>
          {t('恢复默认')}
        </Button>
        <Button
          disabled={!textValue.length}
          loading={loading}
          theme="primary"
          variant="outline"
          className="operate-btn"
          onClick={reqAudition}
        >
          {loading ? t('正在合成') : t('合成试听')}
        </Button>
        {supportLanguages.length > 0 && (
          <div style={{ margin: '24px 0 8px', fontSize: 12, color: '#C74740' }}>
            {t('多语种音色在合成时需选择对应语种')}
          </div>
        )}
        {mode === 'save' && (
          <Button
            variant="outline"
            theme="primary"
            className={classNames('save-btn', { disabled })}
            onClick={saveSetting}
            style={supportLanguages.length > 0 ? { marginTop: 0 } : {}}
          >
            {t('保存并应用')}
          </Button>
        )}
        {disableLanguageFlag && (
          <div style={{ fontSize: 12, color: 'rgba(15, 24, 41, 0.7)', marginTop: 8 }}>{t('该音色不支持合成字幕')}</div>
        )}
      </div>
    </div>
  );
};

export default React.memo(AuditionSetting);
